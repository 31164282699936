// $mc-yellow-0: #F2CF1D;
// $mc-yellow-1: #F2BB16;
// $mc-yellow-2: #F2A413;
// $mc-yellow-3: #F28C0F;

// $mc-yellow-0: #FFDC30;
// $mc-yellow-1: #F2BB16;
// $mc-yellow-2: #F2A413;
// $mc-yellow-3: #F28C0F;

$mc-yellow-0-1: #F3E51C;
$mc-yellow-0: #FFEE32;
$mc-yellow-1: #F2CB05;
$mc-yellow-2: #F2B705;
$mc-yellow-3: #F29F05;

body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}
h1 {
  font-family: "Raleway", sans-serif;
  font-size: 58px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
}
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: $mc-yellow-0;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: $mc-yellow-0;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}

img.block-image {
  width: 100%;
  // background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

div.separator {
  width: 60px;
}

.gradient-icon {
  margin-right: 8px;
  color: $mc-yellow-1;
}

.bg-mc-gradient {
  background-image: linear-gradient(to right, $mc-yellow-0 0%, $mc-yellow-1 100%);
}

.bg-mc-gray {
  background-color: #f6f6f6;
}

.bg-mc-light {
  background-color: #fff;
}

.grad-bg-title {
  color: #333;
}

.grad-bg-text {
  color: #333;
}

.gray-bg-title {
  color: #333;
}

.gray-bg-text {
  color: #555;
}

.white-bg-title {
  color: #333;
}

.white-bg-text {
  color: #777;
}

.gradient-underline {
  position: relative;
  margin-bottom: 14px;
  padding-bottom: 14px;
}
.gradient-underline::after {
  position: absolute;
  content: "";
  background-image: linear-gradient(to right, $mc-yellow-0 0%, $mc-yellow-1 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

.gradient-underline-light {
  position: relative;
  margin-bottom: 14px;
  padding-bottom: 14px;
}
.gradient-underline-light::after {
  position: absolute;
  content: "";
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

.gradient-underline-extra {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.gradient-underline-extra::after {
  position: absolute;
  content: "";
  background-image: linear-gradient(to right, $mc-yellow-0 0%, $mc-yellow-1 100%);
  height: 6px;
  width: 100px;
  bottom: 0;
  left: 0;
}


.gradient-underline-extra-light {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.gradient-underline-extra-light::after {
  position: absolute;
  content: "";
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
  height: 6px;
  width: 100px;
  bottom: 0;
  left: 0;
}

.gradient-underline-mini {
  position: relative;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.gradient-underline-mini::after {
  position: absolute;
  content: "";
  background-image: linear-gradient(to right, $mc-yellow-0 0%, $mc-yellow-1 100%);
  height: 4px;
  width: 40px;
  bottom: 0;
  left: 0;
}

@media (max-width: 320px) {

  .gradient-underline::after {
    width: 50px;
  }

  .gradient-underline-extra::after {
    width: 75px;
  }
}