@import './style.scss';

/* Navigation */
#menu {
  transition: all 0.8s;
}

#menu.navbar {
  background-color: #fff;
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#menu .navbar-nav {
  margin-top: 12px;
  margin-bottom: 8px;
}

#menu .navbar-toggler {
  padding: 0.25rem 0.5rem;
  border: none;
  font-size: 1.5rem;
  color: #333;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

#menu a.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  padding-top: 8px;
  padding-bottom: 8px;
}

#menu a.nav-link {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 8px 20px 0;
  line-height: 12px;
  height: 30px;
  margin-bottom: 8px;
}

#menu a.nav-link:after {
  display: block;
  margin-top: 8px;
  width: 0;
  height: 2px;
  background-image: linear-gradient(to right, $mc-yellow-0 0%, $mc-yellow-1 100%);
  content: "";
  transition: width 0.2s;
}

#menu a.nav-link.active {
  border-style: solid;
  border-width: 0 0 2px 0;
  padding-bottom:5px;
  /* margin-bottom:20px; */
  width:fit-content;
  // border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66)) 1;
  border-image: linear-gradient(45deg, $mc-yellow-0 0%, $mc-yellow-1) 1; 
}

/* #menu .navbar-collapse.collapsing,
#menu .navbar-collapse.show {
  margin-top: 5px;
  padding-top: 7.5px;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #e7e7e7;
  margin-left: -15px;
  margin-right: -15px;
} */

#menu a.nav-link:hover:after {
  width: 100%;
}

.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}

.nav-logo {
  height: 42px;
  margin-right: 8px;
}

.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  // background-color: #5ca9fb;
  // background-image: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); 
  // box-shadow: 0 4px 12px 0 rgba(152, 160, 180, 10);
  // box-shadow: 0px 1px 8px 0px rgba(55, 55, 55, 0.25);
//   background-image: linear-gradient(
//  45deg,#2cfff7,#2afcbe,#43ddfb,#8c57ff);
background-image: linear-gradient(
 45deg, $mc-yellow-0, $mc-yellow-1, $mc-yellow-2, $mc-yellow-0);
  // box-shadow: 0 8px 20px 0 rgba(27,252,190,.25)!important;
  // box-shadow: 0 8px 20px 0 rgba(255, 238, 50, 0.25)!important;
  box-shadow: 0 8px 20px 0 rgba(240, 203, 5, 0.25)!important;
  // box-shadow: 0 4px 12px 0 $mc-yellow-1 !important;
   background-repeat: no-repeat;
   background-size: 300%;
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  // border-radius: 25px;
  transition: all 0.25s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  // background-image: none; 
  // background-color: #6372ff; 
  // transition: all 0.5s linear;
  background-position: right center;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

/* Home Section */
.intro {
  display: table;
  width: 100%;
  padding: 0;
  background: url(../public/img/home/header.jpg) center center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.intro .overlay {
  background: rgba(0, 0, 0, 0.5);
}
.intro h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}
.intro-text {
  text-align: center;
}

#home h3 {
  font-size: 28px;
}

#home p {
  line-height: 24px;
  margin: 30px 0;
  text-align: justify;
}

/* Automation Section */
#automation p {
  line-height: 24px;
  text-align: justify;
  padding-right: 4px;
  padding-left: 0px;
}

#automation li {
  font-size: 15px;
}

#automation h3 {
  font-size: 28px;
}

.accordion-item {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-body {
  padding: 0;
  padding-left: 1.5rem;
  // -o-border-image: linear-gradient(180deg,#1bfcbe,#43ddfb) 1;
  // border-image: linear-gradient(180deg,#1bfcbe,#43ddfb) 1;
  -o-border-image: linear-gradient(180deg, $mc-yellow-0, $mc-yellow-1) 1;
  border-image: linear-gradient(180deg,$mc-yellow-0, $mc-yellow-1) 1;
  border-style: solid;
  border-width: 0 0 0 .25rem;
}

#automation .accordion-body > ul > li > p {
  margin: 0;
  text-align: start;
}

.accordion-body > ul > li {
  padding: 8px 0;
  border-color: rgba(0, 0, 0, 0.125);
  border-style: solid;
  border-width: 1px 0 0 0;
}

.accordion-body > ul > li:first-of-type {
  border: none;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 12px;
  padding-left: 0px;
  padding-right: 2px;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  color: #333;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #333;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../public/img/accordion/dash.svg");
  // background-image: url("../public/img/accordion/chevron-up.svg");
  transform: rotate(-180deg);
}
.accordion-button::after {
  background-image: url("../public/img/accordion/plus.svg");
  // background-image: url("../public/img/accordion/chevron-down.svg");
}

// #automation li {
//   margin-bottom: 6px;
//   margin-left: 6px;
//   list-style: none;
//   padding: 0;
// }
// #automation li:before {
//   content: "\f00c";
//   font-family: "FontAwesome";
//   color: #5ca9fb;
//   font-size: 11px;
//   font-weight: 300;
//   padding-right: 8px;
// }

/* Digitalization Section */
#digitalization p {
  line-height: 24px;
  text-align: justify;
  padding-right: 4px;
  padding-left: 0px;
}

#digitalization li {
  font-size: 15px;
}

#digitalization h3 {
  font-size: 28px;
}

h2.reference {
  font-size: 32px;
}

img.reference {
  width: 80px;
}

.img-badge {
  height: 120px;
  padding: 24px;

  border-radius: 50%;
  background-image: linear-gradient(to right, $mc-yellow-0 0%, $mc-yellow-1 100%);
}

h4.text-badge-title {
  font-size: 16px;
  text-transform: uppercase;
}

#digitalization p.label {
  margin: 0;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: $mc-yellow-1;
}

/* About Section */
#about p {
  line-height: 24px;
  text-align: justify;
  padding-right: 4px;
  padding-left: 0px;
}

/* Contact Section */
#contact p {
  margin-bottom: 8px;
}

#contact p.title {
  margin: 0;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: $mc-yellow-1;
}

#contact p.name {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
}

#contact p.address {
  font-size: 15px;
  font-weight: 700;
  color: #333;
}


#contact .icon {
  color: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
}

/* Footer Section*/
#footer {
  background: #f6f6f6;
}
#footer p {
  color: #888;
  font-size: 11px;
  text-transform: uppercase;
  margin: 0;
}

#footer .nav-link-outlined {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  padding: .625rem 1.25rem;
  border-radius: .25rem;
  border: 1px solid transparent;
  color: #000!important;
  border-color: #000!important;
  transition: all .15s cubic-bezier(.4,0,.2,1);
}

#footer .nav-link-outlined:hover {
  color: $mc-yellow-1 !important;
  border-color: $mc-yellow-1  !important;
}

#footer h3 {
  font-size: 15px;
}

#footer h4 {
  font-size: 13px;
  margin: 0;
}

div.copyright {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: rgba(231, 231, 231, 1);
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#footer p.copyright {
  font-size: 10px;
}

br.responsive {
  display: inline; 
}

#footer .react-icon {
  color: $mc-yellow-0;
  font-size: 42px;
}

.footer-logo {
  height: 36px;
  margin-right: 6px;
}

@media (min-width: 768px) {
  .nav-fixed-height {
    height: 78px;
  }

  br.responsive {
    display: none; 
  } 
}

@media (min-width: 1200px) {

  .container{
    max-width: 1170px;
  }
}

@media (max-width: 1199px) {

  #home h1,
  #automation h1,
  #digitalization h1,
  #about h1,
  #contact h1 {
    font-size: 50px;
  }

  .img-badge {
    height: 112px;
    padding: 20px;
  }

}

@media (max-width: 991px) {

  #home h1,
  #automation h1,
  #digitalization h1,
  #about h1,
  #contact h1 {
    font-size: 42px;
  }

  .img-badge {
    height: 100px;
    padding: 16px;
  }
}

@media (max-width: 767px) {

  #menu .navbar-collapse {
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: #e7e7e7;
    margin-left: -16px;
    margin-right: -16px;
  }

  .nav-logo {
    height: 36px;
    margin-right: 4px;
  }

  .nav-fixed-height {
    height: 64px;
  }

  #about img {
    margin: 50px 0;
  }

  #footer p {
    font-size: 11px;
  }

  #footer h3 {
    font-size: 15px;
  }

  #footer h4 {
    font-size: 13px;
  }

  .img-badge {
    height: 100px;
    padding: 16px;
  }

  #footer .nav-link-outlined {
    font-size: 12px;
  }

  #automation .accordion-body > ul > li > p {
    font-size: 13px;
    line-height: 18px;
    text-align: start;
    padding-right: 4px;
  }
}

@media (max-width: 576px) {
  #automation .accordion-body > ul > li > p {
    font-size: 12px;
    line-height: 18px;
    padding-right: 2px;
  }

  .img-badge {
    height: 120px;
    padding: 24px;
  }
}

@media (max-width: 500px) {
  #home img.partners {
    padding: 2.5rem !important;
  }
}

@media (max-width: 400px) {
  #home h1,
  #automation h1,
  #digitalization h1,
  #about h1,
  #contact h1 {
    font-size: 36px;
  }

  #home h2,
  #automation h2,
  #digitalization h2,
  #about h2,
  #contact h2 {
    font-size: 28px;
  }

  #home h3,
  #automation h3,
  #digitalization h3,
  #about h3,
  #contact h3 {
    font-size: 24px;
  }

  #digitalization h2.reference {
    font-size: 24px;
  }

  #home img.partners {
    padding: 2rem !important;
  }

  
}

@media (max-width: 320px) {
  #home h1,
  #automation h1,
  #digitalization h1,
  #about h1,
  #contact h1 {
    font-size: 30px;
  }

  #home h2,
  #automation h2,
  #digitalization h2,
  #about h2,
  #contact h2 {
    font-size: 24px;
  }

  #home h3,
  #automation h3,
  #digitalization h3,
  #about h3,
  #contact h3 {
    font-size: 20px;
  }

  #digitalization h2.reference {
    font-size: 22px;
  }

  #home img.partners {
    padding: 1.5rem !important;
  }

  #automation img.partners {
    padding-right: 0.75rem !important;
  }

  .accordion-body {
    padding: 0;
    padding-left: 1rem;
  }

}